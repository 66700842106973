import React from "react"
import ButtonLink from "../components/form/ButtonLink"
import styled from "@emotion/styled"
import Layout from "../components/global/layout"
import WhiteLogo from "../images/brand/logo-white.svg"
import { urls } from "../data/urls"

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  text-align: center;
  animation: colorChange 6s infinite linear;
  color: #fff;
  h1 {
    color: #fff;
    margin-bottom: 8px;
  }
  @keyframes colorChange {
    0% {
      background: ${props => props.theme.vanilla};
    }
    25% {
      background: ${props => props.theme.peach};
    }
    50% {
      background: ${props => props.theme.rose};
    }
    75% {
      background: ${props => props.theme.apricot};
    }
    100% {
      background: ${props => props.theme.vanilla};
    }
  }
`

const NotFoundPage = () => (
  <Layout logoOverride={WhiteLogo} marginless hideHamburger>
    <Container>
      <h1>404</h1>
      <p>Sorry about that, let's get you back on track</p>
      <ButtonLink
        to={urls.LANDING}
        buttonStyle={"inverted"}
        backgroundColor={props => props.theme.apricot}
      >
        Back to home
      </ButtonLink>
    </Container>
  </Layout>
)

export default NotFoundPage
